import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// 数据部分
const works = [
    {
        title: 'Haye',
        image: '/images/haye/haye_bg.png',
        logo: '/images/haye/haye_icon.png',
        description: 'In-context AI assistant for macOS',
        downloadLink: 'http://haye.ai',
        introduce: 'Today, we are thrilled to introduce a new product to everyone: Haye, a system-level text intelligence assistant designed for macOS. By invoking Haye, you can streamline your reading, translation, and writing processes. Whether you are composing an important thesis or updating your Twitter, Haye is an indispensable assistant for you. https://haye.ai/',
        style: 'bg-[#212121] ',
        type: 'PRODUCT'

    },
    {
        title: 'EasyDevo',
        image: '/images/easydevo/easydevo_bg.png',
        logo: '/images/easydevo/easydevo_icon.png',
        description: 'An elegant tool, built for coding',
        downloadLink: 'http://easydevo.boringboring.design',
        introduce: 'We‘re thrilled to launch our first app, crafted specifically for developers. This handy tool addresses the growing storage challenges in your development environment, easing the burden of project dependencies, SDKs, and older Xcode versions. It doesn’t stop there; integrated with system monitoring and project analytics, EasyDevo helps you better understand your device performance and review your project contributions.',
        style: 'bg-[#212121]',
        type: 'PRODUCT'

    },
    {
        title: 'Nustime',
        image: '/images/nustime/nustime_bg.png',
        logo: '/images/nustime/nustime_icon.png',
        description: 'A time tool generated 95% by ChatGPT',
        downloadLink: 'https://github.com/boring-design/nustime-distro/releases',
        introduce: 'After nearly a month of weekends delving into platforms and frameworks like Xcode, Electron, and Tauri, I finally created an app largely written by ChatGPT — astoundingly, over 95% of the code was generated by it. For someone without programming knowledge like me, this was not just a product challenge but a fascinating journey',
        style: 'bg-[#212121]',
        type: 'PRODUCT'
    },
    {
        title: 'Notepal [In progress]',
        image: '/images/notepal/notepal_bg.png',
        logo: '/images/nustime/nustime_icon.png',
        description: 'Weread notes synchronization tool',
        downloadLink: 'https://notepal.randynamic.org/',
        introduce: '/',
        style: 'bg-[#212121]',
        type: 'COOPERATION'
    }
    ,
    {
        title: 'Phoenix',
        image: '/images/work/project_p.png',
        logo: '/images/nustime/nustime_icon.png',
        description: 'An open source knowledge management tool',
        downloadLink: '#',
        introduce: '/',
        style: 'bg-[#212121]',
        type: 'COOPERATION'
    }

];

function WorkItem({ work, index }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };


    return (
        <motion.div 
            key={index} 
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            transition={{ ease: "easeInOut", duration: 1, delay: index * 0.3 }}
            className="flex-grow w-full sm:w-1/2 md:w-1/2 xl:w-1/3 pb-[60px] transition-all duration-300 ease-in-out"
            ref={ref}
        >
            <div className='work_title flex flex-col justify-between cursor-pointer md:pr-[24px]'>
                <div 
                    className={`relative rounded-[12px] ${work.style} overflow-hidden bg-[#212121] min-h-[480px]`}
                >
                    <a href={work.downloadLink}>
                        <img alt={work.title} src={work.image} className='w-full min-h-[480px] object-cover rounded-[12px] transform transition-all duration-300  ease-in-out hover:scale-105'></img>
                    </a>
                </div>
                <div className='pt-[24px] Md:p-[12px]'>
                    <div className='text-[24px] text-[#2B2B2B] dark:text-[#E3E3E3] md:text-[24px] font-medium'>
                        <a href={work.downloadLink}>{work.title}</a>
                    </div>
                    <div className='text-[14px] text-[#585858] dark:text-[#9F9F9F] md:text-[16px] font-light'>{work.description}</div>
                </div>
            </div>
        </motion.div>
    );
}




function Work() {
    const [selectedType, setSelectedType] = useState('PRODUCT');
    const filteredWorks = works.filter(work => selectedType === 'ALL' || work.type === selectedType);

    const buttonVariants = {
        selected: { color: "#E3E3E3", scale: 1.1 },
        unselected: { color: "#2A2A2A", scale: 1 }
    };


    return (
    <div>
            <div className='w-full md:max-w-[560px] text-[16px] md:text-[20px] font-light text-[#585858] dark:text-[#9F9F9F]'>We firmly believe that <span className='text-[18px] md:text-[24px] font-medium text-[#2B2B2B] dark:text-[#E3E3E3]'>simplicity holds power</span>, and a user-friendly tool not only streamlines work but also brings joy</div>
            <div className="md:flex md:space-x-4 my-[24px] ">
                <button 
                className={`text-[50px] md:text-[90px] pr-[32px] py-2 ${selectedType === 'PRODUCT' ? 'text-[#2B2B2B] dark:text-[#E3E3E3] font-medium' : 'text-[#c2c2c2] dark:text-[#2a2a2a] font-medium'}`}
                onClick={() => setSelectedType('PRODUCT')}
                variants={buttonVariants}
                initial="unselected"
                animate={selectedType === 'PRODUCT' ? "selected" : "unselected"}

                >
                PRODUCT
                </button>
                <button 
                className={`text-[50px] md:text-[90px]  pr-[32px] py-2 ${selectedType === 'COOPERATION' ? 'text-[#2B2B2B] dark:text-[#E3E3E3] font-medium' : 'text-[#c2c2c2] dark:text-[#2a2a2a]  font-medium'}`}
                onClick={() => setSelectedType('COOPERATION')}
                variants={buttonVariants}
                initial="unselected"
                animate={selectedType === 'COOPERATION' ? "selected" : "unselected"}

                >
                COOPERATION
                </button>
            </div>
            <div className="flex flex-wrap justify-start overflow-x-auto max-w-full">
            {filteredWorks.map((work, index) => (
                    <WorkItem work={work} index={index} key={work.title} />
                ))}
            </div>
        </div>
    );
}

export default Work;