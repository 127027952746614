// OurPlan.js
import React from 'react';
import ThemeSwitch from './Theme/ThemeSwitch';
import logo from '../site_logo.png'

const Footer = () => {
  return (
    <div className='pt-[80px] max-w-[1440px] mx-auto'>
    <div className='border-[0.5px] border-[#e5e5e5] dark:border-[#171717]'></div>
    <div className='md:flex pt-[80px] pb-[24px] justify-between items-start'>

      <div className='flex items-center'>
        <img src={logo} alt="logo" className="h-8 w-8 mr-2" />
        <span className="text-[#2B2B2B] dark:text-[#D3D3D3] text-md font-bold">Boring Design</span>
      </div>
      <div className='md:flex space-y-[40px] md:space-y-[0px] md:space-x-[140px]'>
      <div>
        <div className='text-[#2B2B2B] dark:text-[#fff] text-[14px] pt-[32px] md:pt-[0px] font-bold pb-[24px]'>Product</div>
        <div className='flex flex-col text-[#585858] dark:text-[#7B7B7B] space-y-[12px] text-[14px]'>
          <a href='https://haye.ai/'>Haye</a>
          <a href='https://easydevo.boringboring.design/'>EasyDevo</a>
          <a href='https://github.com/boring-design/nustime-distro/releases'>Nustime</a>
          <a href='https://notepal.randynamic.org/'>Notepal</a>
        </div>
      </div>
      <div>
        <div className='text-[#2B2B2B] dark:text-[#fff] text-[14px] font-bold pb-[24px]'>Community</div>
        <div className='flex flex-col text-[#585858] dark:text-[#7B7B7B] space-y-[12px] text-[14px]'>
          <a href='https://discord.gg/kbnxt7WTPt'>Discord</a>
          <a href='https://github.com/boring-design'>Github</a>
          <a href="mailto:support@boringboring.design">Email</a>
        </div>
      </div>
      <ThemeSwitch />
    </div>
    </div>
    </div>
  );
};

export default Footer;
