import React, { useEffect, useRef, useState } from 'react';

const Marquee = ({ children, speed, className, reverse = false }) => {
  const marqueeRef = useRef(null);
  const containerRef = useRef(null);
  const [xPos, setXPos] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const animationFrameRef = useRef();

  useEffect(() => {
    const updateWidth = () => {
      if (marqueeRef.current && containerRef.current) {
        const width = marqueeRef.current.offsetWidth / 2;
        setTextWidth(width);
        setXPos(reverse ? -width : 0); // Start from -width if reverse to simulate forward motion from off-screen
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [reverse]);

  useEffect(() => {
    const animate = () => {
      setXPos(xPos => {
        if (reverse) {
          // Reset position when moving left-to-right
          return xPos >= 0 ? -textWidth : xPos + speed;
        } else {
          // Reset position when moving right-to-left
          return xPos <= -textWidth ? 0 : xPos - speed;
        }
      });
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [textWidth, speed, reverse]);

  return (
    <div ref={containerRef} className={`relative overflow-hidden whitespace-nowrap ${className ?? ''}`}>
      <div ref={marqueeRef} className="absolute flex" style={{ left: xPos }}>
          <span>{children}</span>
          <span>{children}</span>
      </div>
    </div>
  );
};

export default Marquee;
