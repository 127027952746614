import React, { useEffect, useState } from 'react';
import membersData from './members.json';

const GetDiscord = () => {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        let shuffledMembers;
        const storedMembers = localStorage.getItem('members');

        if (storedMembers) {
            shuffledMembers = JSON.parse(storedMembers);
        } else {
            const filteredMembers = membersData.filter(member => !member.avatar.includes('embed'));
            shuffledMembers = filteredMembers.sort(() => 0.5 - Math.random()).slice(0, 5);
            localStorage.setItem('members', JSON.stringify(shuffledMembers));
        }

        setMembers(shuffledMembers);
    }, []);

    return (
        <div className="flex space-x-[-10px]">
            {members.map((member, index) => (
                <p key={index} title={member.name} className="relative group transform hover:translate-x-0 hover:~*translate-x-[20px] transition-transform duration-200 opacity-0 animate-fade-in">
                    <div className="bg-gray-500 h-10 w-10 rounded-full border-[2px] border-[#fff]" style={{ backgroundImage: `url(${member.avatar})`, backgroundSize: 'cover' }}></div>
                    <span className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 text-sm text-white bg-black rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap px-2 py-2">{member.name}</span>
                </p>
            ))}
        </div>
    );
};

export default GetDiscord;