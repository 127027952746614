import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const Image = ({ src, alt, ...props }) => {
  const { theme, systemTheme } = useContext(ThemeContext);
  const imageSrc = theme === 'system' ? (src[systemTheme] || src['light']) : (src[theme] || src['light']);

  return <img src={imageSrc} alt={alt} {...props} />;
};

export default Image;