import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="flex flex-col items-center justify-center pt-[160px] pb-[60px]">
        <div className="flex mb-5 space-x-[20px] pb-[24px]">
            <a href="https://haye.ai">
                <motion.img 
                    src='images/bg/Haye_icon.png' 
                    alt='Haye' 
                    className='w-[80px]'
                    ref={ref}
                    animate={controls}
                    initial={{ opacity: 0, rotate: -15 }}
                    whileHover={{ scale: 1.15, rotate: -20 }}
                    variants={{
                      visible: { opacity: 1, transition: { delay: 0.1 } },
                      hidden: { opacity: 0 }
                    }}
                />
            </a>
            <a href="https://github.com/boring-design/nustime-distro/releases">
                <motion.img 
                    src='images/bg/Nustime_icon.png' 
                    alt='Nustime' 
                    className='w-[80px]'
                    ref={ref}
                    animate={controls}
                    initial={{ opacity: 0, rotate: 15 }}
                    whileHover={{ scale: 1.15, rotate: 20 }}
                    variants={{
                      visible: { opacity: 1, transition: { delay: 0.2 } },
                      hidden: { opacity: 0 }
                    }}
                />
            </a>
            <a href="https://easydevo.boringboring.design">
                <motion.img 
                    src='images/bg/Easydevo_icon.png' 
                    alt='Easydevo' 
                    className='w-[80px]'
                    ref={ref}
                    animate={controls}
                    initial={{ opacity: 0, rotate: -15 }}
                    whileHover={{ scale: 1.15, rotate: -20 }}
                    variants={{
                      visible: { opacity: 1, transition: { delay: 0.3 } },
                      hidden: { opacity: 0 }
                    }}
                />
            </a>
            <a href="https://notepal.randynamic.org/">
                <motion.img 
                    src='images/bg/Notepal_icon.png' 
                    alt='Notepal' 
                    className='w-[80px]'
                    ref={ref}
                    animate={controls}
                    initial={{ opacity: 0, rotate: 15 }}
                    whileHover={{ scale: 1.15, rotate: 20 }}
                    variants={{
                      visible: { opacity: 1, transition: { delay: 0.4 } },
                      hidden: { opacity: 0 }
                    }}
                />
            </a>
        </div>
        <h1 className="mb-5 text-[32px] md:text-[42px] font-medium text-[#2B2B2B] dark:text-[#E3E3E3] pb-[24px] text-center">Creating products, harvesting joy</h1>
        <a href="mailto:support@boringboring.design">
            <button className="bg-[#232323] hover:bg-[#3C3C3C] text-button-discord-text h-[48px] text-[16px] px-[32px] rounded-3xl font-bold transition-colors duration-200 ease-in-out">Contact Us</button>         
        </a>
    </div>
  );
}

export default Contact;