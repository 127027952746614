import React from 'react';
import TextBanner from './TextBanner';

const Introduce = () => {
  return (
    <div className='pt-[48px]'>
    <div className='z-[-100]'><TextBanner/></div>
    <div className='flex max-w-[1440px] mx-auto overflow-x-hidden'>
        <div className='md:flex items-center  mx-auto'>
          <div className='md:w-1/2 md:p-[40px] xl:p-[100px]'>
            <div className='text-[32px] text-[#2B2B2B] dark:text-[#E3E3E3] font-bold pb-[24px]'>This is the beginning of a wonderful journey</div>
            <div className='text-[#585858] dark:text-[#9F9F9F] font-light max-w-[500px] text-[18px] mb-[24px]'>Fuelled by passion, after thoughtful deliberation, we embarked on an app development journey, founding the Boring Design studio. We aim to deliver meaningful tools to our users through our expertise.</div>
            <div className='text-[#585858] dark:text-[#9F9F9F] font-light max-w-[500px] text-[18px] mb-[24px]'>Of course, we understand that the app market is fiercely competitive, brimming with excellent products. Yet, it is this very environment that provides us the platform to challenge ourselves and surpass our limits. We are dedicated to developing unique and superior app experiences, breaking through the monotony of daily life to deliver innovation and practical value.</div>
          </div>
          <div className='md:w-1/2 md:p-[20px] xl:p-[40px]'>
            <img src='/images/bg/journey_design_bg.svg' alt='22'></img>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Introduce;