import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Marquee from './Marquee'

gsap.registerPlugin(ScrollTrigger)

function clampValue(value, min, max) {
  return Math.min(Math.max(value, min), max)
}

function mapAndClamp(value, inMin, inMax, outMin, outMax) {
  // Map the value
  const mappedValue = (value - inMin) / (inMax - inMin) * (outMax - outMin) + outMin
  // Clamp the mapped value
  return clampValue(mappedValue, outMin, outMax)
}

const TextBanner = () => {
  const containerRef = useRef(null)

  const [lastPosition, setLastPosition] = useState(0)
  const [lastTime, setLastTime] = useState(Date.now())
  const [scrollSpeed, setScrollSpeed] = useState(0)
  const scrollTimeout = useRef(null)

  const handleScroll = useCallback(() => {
    const currentPosition = window.scrollY
    const currentTime = Date.now()
    const timeDiff = currentTime - lastTime
    const positionDiff = currentPosition - lastPosition
    const speed = positionDiff / timeDiff * 1000

    setScrollSpeed(Math.abs(speed))
    setLastPosition(currentPosition)
    setLastTime(currentTime)

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current)
    }

    scrollTimeout.current = setTimeout(() => {
      setScrollSpeed(0)
    }, 200)
  }, [lastPosition, lastTime])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }
    }
  }, [handleScroll])

  const marquee1Speed = useMemo(() =>
      mapAndClamp(scrollSpeed, 0, 8000, 1.2, 10),
    [scrollSpeed]
  )
  const marquee2Speed = useMemo(() =>
      mapAndClamp(scrollSpeed, 0, 8000, 1.5, 10),
    [scrollSpeed]
  )

  return (
    <div className="flex -mx-[42px] pt-[48px] w-screen overflow-x-hidden">
      <div
        ref={containerRef}
        className="overflow-hidden leading-none w-full [--mq-font-size:160px]"
      >
        <div className="pb-[32px]">
          <Marquee
            className="text-[length:--mq-font-size] h-[--mq-font-size] text-[#2B2B2B] dark:text-[#E3E3E3] font-medium"
            speed={marquee1Speed}
          >
            HAYE * EASYDEVO * NUSTIME * BORING DESGIN *&nbsp;
          </Marquee>
        </div>
        <div className="pb-[32px]">
          <Marquee
            className="text-[length:--mq-font-size] h-[--mq-font-size] text-[#2B2B2B] dark:text-[#E3E3E3] font-medium"
            speed={marquee2Speed}
            reverse
          >
            HAYE * EASYDEVO * NUSTIME * BORING DESGIN *&nbsp;
          </Marquee>
        </div>
      </div>
    </div>
  )
}

export default TextBanner