import React, { useReducer, useContext, useEffect } from 'react';
import { ThemeContext } from './ThemeContext';
import Image from './Image';

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'LIGHT':
      return 'light';
    case 'DARK':
      return 'dark';
    case 'SYSTEM':
      return 'system';
    default:
      throw new Error();
  }
};

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [localTheme, dispatch] = useReducer(themeReducer, theme);

  const handleThemeChange = (newTheme) => {
    if (newTheme !== localTheme) {
      dispatch({ type: newTheme.toUpperCase() });
      toggleTheme(newTheme);
    }
  };

  useEffect(() => {
    dispatch({ type: theme.toUpperCase() });
  }, [theme]);

  return (
    <div>
      <button className="flex dark:bg-[#0C0C0C] px-[4px] py-[4px] border border-[#dfdede] dark:border-[#1b1b1b] rounded-full space-x-[0px]">
        <div className={`p-[4px] rounded-2xl  ${localTheme === 'light' ? 'bg-[#ffffff] dark:bg-[#232323]' : ''}`} onClick={() => handleThemeChange('light')}>
            <Image 
                src={{
                    light: '/images/bg/appearance_light_light.svg',
                    dark: '/images/bg/appearance_light_dark.svg',
                }}
                alt='Light Mode' 
                className='w-5 h-5 ' 
            />
        </div>
        <div className={`p-[4px] rounded-2xl ${localTheme === 'dark' ? 'bg-[#ffffff] dark:bg-[#232323]' : ''}`} onClick={() => handleThemeChange('dark')}>
            <Image 
                src={{
                    light: '/images/bg/appearance_dark_light.svg',
                    dark: '/images/bg/appearance_dark_dark.svg',
                }}
                alt='Dark Mode' 
                className='w-5 h-5 ' 
            />
            </div>
        <div className={`p-[4px] rounded-2xl ${localTheme === 'system' ? 'bg-[#ffffff] dark:bg-[#232323]' : ''}`} onClick={() => handleThemeChange('system')}>
            <Image 
                src={{
                    light: '/images/bg/appearance_auto_light.svg',
                    dark: '/images/bg/appearance_auto_dark.svg',
                }}
                alt='System Mode' 
                className='w-5 h-5 ' 
            />
            </div>
      </button>
    </div>
  );
};

export default ThemeSwitch;