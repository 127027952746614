import React, { useEffect, useRef } from 'react';
import anime from 'animejs';
import Image from './Theme/Image';


const OurPlan = () => {
  const pathRef1 = useRef(null);
  const pathRef2 = useRef(null);

  useEffect(() => {
    anime({
      targets: pathRef1.current,
      fill: ['#64C32A', '#64C32A'],
      duration: 2000,
      easing: 'easeInOutSine',
      direction: 'alternate',
      loop: true
    });

    anime({
      targets: pathRef2.current,
      fill: ['#64C32A', '#64C32A'],
      duration: 2000,
      easing: 'easeInOutSine',
      direction: 'alternate',
      loop: true
    });
  }, []);
  
  return (
    <div className='pt-24 max-w-[1440px] mx-auto '>
      <div className='md:flex '>
        <div className='md:w-1/2'>
          <h1 className='text-[68px] text-[#2B2B2B] dark:text-[#E3E3E3] font-medium pt-[16px] max-w-[600px] leading-tight'>Our plan for the next three years</h1>
          <div className='text-[#585858] dark:text-[#9F9F9F] font-light max-w-[500px] text-[18px] my-[40px]'>This is our public program and the original intention of setting up this studio. We hope that the following three goals will guide us in the direction of continuous progress</div>
          <div className='text-[#9F9F9F] font-light max-w-[360px] text-md mt-[80px]  flex flex-col space-y-[64px]'>
            <div>
              <div className='text-[24px] text-[#2B2B2B] dark:text-[#E3E3E3] font-bold'>Objective 1</div>
              <div className='text-[16px] text-[#585858] dark:text-[#9F9F9F]  pt-[12px]'>Build <span className='text-[#64C32A]'> 5+ paid productivity tools  </span>  to sustain ourselves</div>
            </div>
            <div>
              <div className='text-[24px] text-[#2B2B2B] dark:text-[#E3E3E3] font-bold'>Objective 2</div>
              <div className='text-[16px] text-[#585858] dark:text-[#9F9F9F]  pt-[12px]'>Empower more people by providing free services to <span className='text-[#64C32A] '>20+ independent developers </span></div>
            </div>
            <div>
              <div className='text-[24px] text-[#2B2B2B] dark:text-[#E3E3E3] font-bold'>Objective 3</div>
              <div className='text-[16px]text-[#585858] dark:text-[#9F9F9F]  pt-[12px]'>Support<span className='text-[#64C32A]'> 20+ open source projects </span> through sponsorship, contribution, redesign, etc.</div>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap mt-[100px] md:mt-[0px]  justify-between text-[#2B2B2B] dark:text-[#E3E3E3] max-h-[600px]'>
          <div className='md:w-1/2 md:p-[50px] flex flex-col'>
            <div className='text-[24px] md:text-[32px]'>Product</div>
            <div className='text-[60px] md:text-[120px] font-medium'>01</div>
            <Image 
              src={{
                light: '/images/bg/plan_line_light.svg',
                dark: '/images/bg/plan_line_dark.svg',
              }}
              alt='free' 
              className='hidden md:block absolute mt-[140px] ml-[100px]'
            />

          </div>
          <div className='w-1/2 md:p-[50px] flex flex-col'>
            <div className='text-[24px] md:text-[32px]'>Free Services</div>
            <div className='text-[60px] md:text-[120px] font-medium'>03</div>
            <Image 
              src={{
                light: '/images/bg/plan_line2_light.svg',
                dark: '/images/bg/plan_line2_dark.svg',
              }}
              alt='free' 
              className='hidden md:block absolute mt-[230px] ml-[-100px]'
            />

          </div>
          <div className='w-1/2 md:p-[50px] flex flex-col'>
            <div className='text-[24px] md:text-[32px]'>Sponsoring</div>
            <div className='text-[60px] md:text-[120px] font-medium'>00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPlan;