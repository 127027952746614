import React from 'react';
import { motion } from 'framer-motion';
import GetDiscord from './Discord/GetDiscord';

const Main = () => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section className="md:py-[40px]">
      <motion.div
        className='text-left '
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ ease: "easeOut", duration: 1, delay: 0.3 }}
      >
        <p className=" text-[#2B2B2B] dark:text-[#E3E3E3] text-[50px] md:text-[100px] font-extralight ">
          Build useful tools,
        </p>
      </motion.div>
      <motion.div
        className='text-left '
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ ease: "easeOut", duration: 1, delay: 0.6 }}
      >
        <p className=' text-[#2B2B2B] dark:text-[#E3E3E3] text-[50px] md:text-[160px] font-semibold leading-tight'>
          unleash infinite imagination.
        </p>
      </motion.div>
      <motion.div
        className='text-left '
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ ease: "easeOut", duration: 1, delay: 0.9 }}
      >
        <p className="text-[#585858] dark:text-[#9F9F9F] md:max-w-[620px] text-[18px] pt-[24px] md:text-[20px] font-light">
        Boring Design is a product design studio that specializes in building productivity tools. We develop our own products and assist independent developers in bringing their products to market        
        </p>    
      </motion.div>
      <motion.div 
      className="flex items-center space-x-4 py-[46px]"
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ ease: "easeOut", duration: 1, delay: 1.2 }}
      >
        <a href='https://discord.gg/kbnxt7WTPt'>
          <button className="bg-[#232323] hover:bg-[#3C3C3C] text-button-discord-text h-10 text-sm px-6 rounded-3xl font-bold transition-colors duration-200 ease-in-out">Join Discord</button>         
        </a>
        <GetDiscord/>
      </motion.div>
    </section>
  );
};

export default Main;