import './App.css';
import OurPlan from './component/OurPlan';
import Footer from './component/Footer';
import Services from  './component/Services';
import Main from './component/Main';
import Header from './component/Header';
import Introduce from './component/Introduce';
import Work from './component/Case';
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Contact from './component/Contact';
import { ThemeProvider } from './component/Theme/ThemeContext';

function App() {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
  });
  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <ThemeProvider>
      <div className="p-[12px] md:p-[42px] mx-auto bg-[#f0f0f0] dark:bg-[#000] md:dark:bg-[#0C0C0C]">     
        <Header/>
        <motion.div
          ref={ref1}
          initial="hidden"
          animate={inView1 ? "visible" : "hidden"}
          variants={variants}
          transition={{ ease: "easeOut", duration: 1 }}
        >
          <Main/>
        </motion.div>
        <motion.div
          ref={ref2}
          className="pt-[100px]"
          initial="hidden"
          animate={inView2 ? "visible" : "hidden"}
          variants={variants}
          transition={{ ease: "easeOut", duration: 1 }}
        >
          <Work/>
        </motion.div>
        <motion.div
          ref={ref3}
          className=""
          initial="hidden"
          animate={inView3 ? "visible" : "hidden"}
          variants={variants}
          transition={{ ease: "easeOut", duration: 1 }}
        >
          <Introduce />
        </motion.div>
        <motion.div
          ref={ref4}
          className=""
          initial="hidden"
          animate={inView4 ? "visible" : "hidden"}
          variants={variants}
          transition={{ ease: "easeOut", duration: 1 }}
        >
          <Services />
        </motion.div>
        <motion.div 
        ref={ref5}
        className="py-[100px]"
        initial="hidden"
        animate={inView5 ? "visible" : "hidden"}
        variants={variants}
        transition={{ ease: "easeOut", duration: 1 }}
        
        >
          <OurPlan />
        </motion.div>
        <Contact/>
        <Footer />
      </div>
    </ThemeProvider>

  );
}

export default App;