import React, { useState, useEffect, useLayoutEffect } from 'react'
import logo from '../site_logo.png'
import { throttle } from 'lodash-es'

import { ArrowUpRightIcon, Bars3Icon, XCircleIcon } from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setIsMounted(true)
    }, 200)
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleScroll = throttle(() => {
      const isScrolled = window.scrollY > 50
      setIsScrolled(isScrolled)
    }, 100)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const spring = {
    type: 'spring',
    damping: 60,
    stiffness: 600,
  }

  const NormalNav = () => (
    <motion.nav className="flex items-center justify-between pt-[12px] md:pt-[24px]" layoutId="nav">
      <motion.a layoutId="logo" className="flex items-center" href="/" transition={spring}>
        <motion.img src={logo} alt="logo" className="h-8 w-8 mr-2" />
        <span className="text-[#0C0C0C] dark:text-[#E3E3E3] text-md font-bold">Boring Design</span>
      </motion.a>
      <motion.div layoutId="menu" className="hidden md:flex items-center justify-end">
        <a href="https://github.com/boring-design"
           className="ml-6 text-sm font-medium flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A] transition-colors duration-200 ease-in-out">GitHub <ArrowUpRightIcon
          className="w-4 h-4 ml-1"/></a>
        <a href="https://discord.gg/kbnxt7WTPt"
           className="ml-6 text-sm font-medium  flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A] transition-colors duration-200 ease-in-out">Discord <ArrowUpRightIcon
          className="w-4 h-4 ml-1"/></a>
        <a href="https://discord.gg/QdVbmdZyUf"
           className="ml-6 text-sm font-medium flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A] transition-colors duration-200 ease-in-out">Design
          Support Program <ArrowUpRightIcon className="w-4 h-4 ml-1"/></a>
      </motion.div>
      <motion.div layoutId="mobile-menu" className="flex justify-end items-center md:hidden">
        <button onClick={toggleMenu} className="absolute z-50">
          <Bars3Icon className="w-8 h-8 text-[#0C0C0C] dark:text-[#E3E3E3]"/>
        </button>
      </motion.div>
    </motion.nav>
  )

  const ScrolledNav = () => (
    <motion.nav
      className="flex items-center justify-between mt-[12px] md:mt-[24px] fixed w-[96%] md:max-w-[800px] p-[16px] rounded-full left-0 right-0 ml-auto mr-auto z-[20] bg-[#ffffff77] border border-[#dfdfdf] dark:border-[#343434] dark:bg-[#2d2d2d77]"
      layoutId="nav"
      initial={{
        backdropFilter: 'blur(0px)',
      }}
      animate={{
        backdropFilter: 'blur(12px)',
      }}
      exit={{
        backdropFilter: 'blur(0px)',
      }}
    >
      <motion.a
        layoutId="logo"
        className="flex items-center"
        href="/"
        transition={spring}
      >
        <img src={logo} alt="logo" className="h-8 w-8 mr-2" />
        <span className="text-[#0C0C0C] dark:text-[#E3E3E3] text-md font-bold">Boring Design</span>
      </motion.a>
      <motion.div layoutId="menu">
        <div className="hidden md:flex items-center justify-end">
          <a href="https://github.com/boring-design"
             className="ml-6 text-sm font-medium flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A] transition-colors duration-200 ease-in-out">GitHub <ArrowUpRightIcon
            className="w-4 h-4 ml-1"/></a>
          <a href="https://discord.gg/kbnxt7WTPt"
             className="ml-6 text-sm font-medium  flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A]  transition-colors duration-200 ease-in-out">Discord <ArrowUpRightIcon
            className="w-4 h-4 ml-1"/></a>
          <a href="https://discord.gg/QdVbmdZyUf"
             className="ml-6 text-sm font-medium flex items-center text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A]  transition-colors duration-200 ease-in-out">Design
            Support Program <ArrowUpRightIcon className="w-4 h-4 ml-1"/></a>
        </div>
        <motion.div layoutId="mobile-menu" className="flex justify-end items-center md:hidden">
          <button onClick={toggleMenu} className="absolute z-50">
            <Bars3Icon className="w-8 h-8 text-[#0C0C0C] dark:text-[#E3E3E3]"/>
          </button>
        </motion.div>
      </motion.div>
    </motion.nav>
  )

  return (
    <>
      <div className="h-40">
        <AnimatePresence>
          {isMounted && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <AnimatePresence initial={false} mode="wait">
                {isScrolled ? <ScrolledNav/> : <NormalNav/>}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {isOpen && (
        <div className="fixed top-[0px] right-0 bottom-0 left-0 bg-[#fff] dark:bg-[#000] flex items-center justify-center z-50"
             style={{ animation: 'slideIn 0.8s forwards' }}>
          <div>
            <button onClick={toggleMenu} className="absolute top-[24px] right-[18px] z-50"
                    style={{ animation: 'slideIn 0.8s 0.8s forwards', visibility: 'hidden' }}>
              <XCircleIcon className="text-[#0C0C0C] dark:text-[#E3E3E3] w-8 h-8"/>
            </button>
            <a href="https://github.com/boring-design"
               className="flex items-center text-3xl mb-8 font-medium text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A]  transition-colors duration-200 ease-in-out"
               style={{ animation: 'slideIn 0.8s 0.6s forwards', visibility: 'hidden' }}>Github <ArrowUpRightIcon
              className="w-6 h-6 ml-4"/></a>
            <a href="https://discord.gg/kbnxt7WTPt"
               className="flex items-center  text-3xl  mb-8 font-medium text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A]  transition-colors duration-200 ease-in-out"
               style={{ animation: 'slideIn 0.8s 0.4s forwards', visibility: 'hidden' }}>Discord <ArrowUpRightIcon
              className="w-6 h-6 ml-4"/></a>
            <a href="https://discord.com/channels/1183363132421324820/1184127443213234326"
               className="flex items-center text-3xl font-medium text-[#0C0C0C] dark:text-[#E3E3E3] hover:text-[#64C32A] dark:hover:text-[#64C32A]  transition-colors duration-200 ease-in-out"
               style={{ animation: 'slideIn 0.8s 0.2s forwards', visibility: 'hidden' }}>Design Support
              Program <ArrowUpRightIcon className="w-6 h-6 ml-4"/></a>
          </div>
        </div>
      )}
    </>
  )
}

export default Header
