// services.js
import React from 'react';
import Image from './Theme/Image';

const Services = () => {
  return (
    <div className='justify-between items-center max-w-[1440px] mx-auto pt-[48px] relative'>
      <div className='order-2 md:order-1 flex'>
        <div className='w-full md:w-4/5 pt-[200px]'>
          <h1 className='text-[48px] md:text-[68px] text-[#2B2B2B] dark:text-[#E3E3E3] font-medium pb-[16px] leading-tight'>We build applications and provide services to those in need</h1>
          <div className='md:flex pt-[80px] md:space-x-[80px]'>
            <div className=' text-[#585858] dark:text-[#9F9F9F] font-light w-full  py-[12px] text-[18px] mb-[24px]'>We understand that software development is not an easy task, especially for many independent developers. Creating an application with an excellent user experience that can also succeed in the market is a significant challenge. </div>
            <div className='text-[#585858] dark:text-[#9F9F9F] font-light w-full  py-[12px] text-[18px] mb-[24px]'> Therefore, we hope to contribute our professional skills and resources here to collaborate with everyone in bringing certain product ideas to fruition. Whether you are an independent developer, a designer, or a small startup, feel free to contact us to explore collaboration opportunities.</div>
        </div>
        </div>
      </div>
        <div className='md:flex space-y-[24px] md:space-x-[16px] md:space-y-[0px] pt-[60px] md:pt-[120px]'>
            <div className='md:w-1/3  rounded-[12px] text-left  flex md:flex-col  items-center md:items-start'>
                <Image 
                  src={{
                    light: '/images/bg/services_free_light.svg',
                    dark: '/images/bg/services_free_dark.svg',
                  }}
                  alt='free' 
                  className='w-[40px] h-[40px] md:w-[60px] md:h-[60px]'
                />
                <div className='pl-[24px] md:pl-[0px] md:w-[340px]'>
                  <div className='text-[24px] text-[#2B2B2B] dark:text-[#fff] font-medium  w-[100px] md:pt-[32px]'>Free</div>
                  <div className='text-[#585858] dark:text-[#9F9F9F] text-md font-light pt-[4px]'>Get free product design service support, including product design, interface design, etc.</div>
                </div>
            </div>
            <div className='md:w-1/3  rounded-[12px]  flex md:flex-col  items-center md:items-start'>
                <Image 
                  src={{
                    light: '/images/bg/services_pro_light.svg',
                    dark: '/images/bg/services_pro_dark.svg',
                  }}
                  alt='free' 
                  className='w-[40px] h-[40px] md:w-[60px] md:h-[60px]'
                />
                <div className='pl-[24px] md:pl-[0px] md:w-[340px]'>
                  <div className='text-[24px] text-[#2B2B2B] dark:text-[#fff] font-medium w-[100px] md:pt-[32px]'>Pro</div>
                  <div className='text-[#585858] dark:text-[#9F9F9F] text-md font-light pt-[4px] '>Engage with a professional team to ensure cost efficiency and product quality, and accelerate product release</div>
                </div>
            </div>
            <div className='md:w-1/3 rounded-[12px] flex md:flex-col  items-center md:items-start'>
                <Image 
                      src={{
                        light: '/images/bg/services_partner_light.svg',
                        dark: '/images/bg/services_partner_dark.svg',
                      }}
                      alt='free' 
                      className='w-[40px] h-[40px] md:w-[60px] md:h-[60px]'
                    />
                <div className='pl-[24px] md:pl-[0px] md:w-[340px]'>
                  <div className='text-[24px] text-[#2B2B2B] dark:text-[#fff] font-medium w-[100px] md:pt-[32px]'>Partner</div>
                  <div className='text-[#585858] dark:text-[#9F9F9F] text-md font-light pt-[4px] '>Become a long-term partner and take responsibility for the project in the long term to achieve common goals</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Services;